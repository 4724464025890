@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes custom-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .custom-spin {
    animation: custom-spin 16s linear infinite;
  }